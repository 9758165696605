const Features = {
  install(Vue, opts = {}) {
    Vue.prototype.$features = {
      enabledFor: (feature, actor) => {
        if (!actor || (actor && !actor.attributes)) {
          console.log('[INFO] Actor isnt valid', actor)
          return false
        }

        if (!actor.attributes.features) {
          console.log('[INFO] Actor doesnt have any featuers present', actor)
          return false
        }

        const features = actor.attributes.features || {}

        return features[feature] || false
      },
    }
  },
}

export default Features
