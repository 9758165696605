var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"autocomplete-wrapper sitewide-search-wrapper",staticStyle:{"position":"relative"}},[(!_vm.searching)?_c('i',{staticClass:"fa fa-search",staticStyle:{"position":"absolute","right":"10px","top":"-12px","font-size":"1.3em"},attrs:{"aria-hidden":"true"}}):_vm._e(),_vm._v(" "),(_vm.searching)?_c('i',{staticClass:"fa fa-spinner fa-spin",staticStyle:{"position":"absolute","right":"10px","top":"-12px","font-size":"1.3em"}}):_vm._e(),_vm._v(" "),_c('vue-autosuggest',{attrs:{"suggestions":_vm.suggestions,"sectionConfigs":_vm.sectionConfigs,"input-props":_vm.inputProps,"should-render-suggestions":_vm.shouldRenderSuggestions},on:{"input":_vm.debouncedFetchResults},scopedSlots:_vm._u([{key:"default",fn:function({ suggestion }){return _c('div',{staticStyle:{"display":"flex","align-items":"center"}},[(suggestion && suggestion.item.type === 'inspection')?[_c('img',{style:({
            display: 'flex',
            width: '35px',
            height: '35px',
            borderRadius: '50%',
            marginRight: '15px',
          }),attrs:{"src":suggestion.item.attributes.image_url}}),_vm._v(" "),_c('div',[_c('div',[_vm._v(_vm._s(suggestion.item.attributes.full_address))]),_vm._v(" "),_c('div',{staticStyle:{"color":"#9ca3af","font-size":"0.9em","margin-top":"5px"}},[_vm._v(_vm._s(_vm.formatDate(suggestion.item.attributes.datetime)))])])]:_vm._e(),_vm._v(" "),(suggestion && suggestion.item.type === 'connection')?[_c('img',{style:({
            display: 'flex',
            width: '35px',
            height: '35px',
            borderRadius: '50%',
            marginRight: '15px',
          }),attrs:{"src":suggestion.item.attributes.image}}),_vm._v(" "),_c('div',[_c('div',[_vm._v(_vm._s([
              suggestion.item.attributes.last_name,
              suggestion.item.attributes.first_name,
            ]
              .filter((a) => a)
              .join(', ')))]),_vm._v(" "),(suggestion.item.attributes.agency_name)?_c('div',{staticClass:"connection-agency"},[_vm._v(_vm._s(suggestion.item.attributes.agency_name))]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"connection-email-phone"},[_vm._v("\n            "+_vm._s(suggestion.item.attributes.email)+" |\n            "+_vm._s(suggestion.item.attributes.phone)+"\n          ")])])]:_vm._e()],2)}}]),model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}},[_vm._v(" "),(_vm.paginated.connection)?_c('div',{attrs:{"slot":"after-section-connection"},slot:"after-section-connection"},[_c('a',{staticClass:"autosuggest__results-item"},[_vm._v("Click here to view more results")])]):_vm._e(),_vm._v(" "),(_vm.suggestions.length === 0)?_c('template',{slot:"after-suggestions"},[_c('div',{staticClass:"autosuggest__results-after"},[_vm._v("No results found matching that criteria.")])]):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }