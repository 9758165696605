import Vue from 'vue'
import SitewideSearch from '../components/header/SitewideSearch'

import VueAutosuggest from 'vue-autosuggest'
import Features from '../plugins/featuresPlugin'
Vue.use(VueAutosuggest)
Vue.use(Features)

const loadComponents = () => {
  // Make sure Vue is loaded
  if (typeof Vue === 'undefined') {
    setTimeout(loadComponents, 500)
    return
  }

  const loggedInDataEl = document.getElementsByClassName('js-logged-in-data')

  if (loggedInDataEl.length > 0) {
    const isCompanyAdmin =
      loggedInDataEl[0].dataset['isCompanyAdmin'] !== undefined
    const profileType = loggedInDataEl[0].dataset.profileType
    const isValidProfile =
      profileType === 'Manager' || profileType === 'Inspector'

    if (isCompanyAdmin || isValidProfile) {
      window.sitewideSearchApp = new Vue({
        // eslint-disable-line no-unused-vars
        el: '#js-sitewide-search',
        render: (h) => h(SitewideSearch),
      })
    }
  }
}

document.addEventListener('DOMContentLoaded', () => {
  loadComponents()
})
