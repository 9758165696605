<template>
  <div
    class="autocomplete-wrapper sitewide-search-wrapper"
    style="position: relative"
  >
    <i
      v-if="!searching"
      class="fa fa-search"
      aria-hidden="true"
      style="position: absolute; right: 10px; top: -12px; font-size: 1.3em"
    />
    <i
      v-if="searching"
      class="fa fa-spinner fa-spin"
      style="position: absolute; right: 10px; top: -12px; font-size: 1.3em"
    />
    <vue-autosuggest
      v-model="query"
      :suggestions="suggestions"
      :sectionConfigs="sectionConfigs"
      :input-props="inputProps"
      :should-render-suggestions="shouldRenderSuggestions"
      @input="debouncedFetchResults"
    >
      <div
        slot-scope="{ suggestion }"
        style="display: flex; align-items: center"
      >
        <template v-if="suggestion && suggestion.item.type === 'inspection'">
          <img
            :style="{
              display: 'flex',
              width: '35px',
              height: '35px',
              borderRadius: '50%',
              marginRight: '15px',
            }"
            :src="suggestion.item.attributes.image_url"
          />
          <div>
            <div>{{ suggestion.item.attributes.full_address }}</div>
            <div style="color: #9ca3af; font-size: 0.9em; margin-top: 5px">{{
              formatDate(suggestion.item.attributes.datetime)
            }}</div>
          </div>
        </template>
        <template v-if="suggestion && suggestion.item.type === 'connection'">
          <img
            :style="{
              display: 'flex',
              width: '35px',
              height: '35px',
              borderRadius: '50%',
              marginRight: '15px',
            }"
            :src="suggestion.item.attributes.image"
          />
          <div>
            <div>{{
              [
                suggestion.item.attributes.last_name,
                suggestion.item.attributes.first_name,
              ]
                .filter((a) => a)
                .join(', ')
            }}</div>
            <div
              class="connection-agency"
              v-if="suggestion.item.attributes.agency_name"
              >{{ suggestion.item.attributes.agency_name }}</div
            >
            <div class="connection-email-phone">
              {{ suggestion.item.attributes.email }} |
              {{ suggestion.item.attributes.phone }}
            </div>
          </div>
        </template>
      </div>
      <div slot="after-section-connection" v-if="paginated.connection">
        <a class="autosuggest__results-item">Click here to view more results</a>
      </div>
      <template slot="after-suggestions" v-if="suggestions.length === 0">
        <div class="autosuggest__results-after"
          >No results found matching that criteria.</div
        >
      </template>
    </vue-autosuggest>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import { VueAutosuggest } from 'vue-autosuggest'
import { debounce } from 'lodash'

const searchableTypes = [
  { name: 'Inspections', type: 'inspection' },
  { name: 'Clients', type: 'contact' },
  { name: 'Agents', type: 'connection' },
]

const buildSectionConfigs = (vm) => {
  const sectionConfigs = {
    default: {
      onSelected: () => {
        if (
          (vm.suggestions.find((s) => s.name === 'connection') || []).length !==
          0
        ) {
          window.location = `/search/connections/${vm.query}`
        }
      },
    },
  }

  searchableTypes.forEach((sectionType) => {
    sectionConfigs[sectionType.type] = {
      label: sectionType.name,
      onSelected: (selected) => {
        const item = selected.item
        vm.selected = null
        vm.query = null
        if (item.type === 'inspection') {
          window.location = `/inspection/${item.attributes.slug}/edit`
        } else if (item.type === 'connection') {
          if (vm.hasAgentsReportsEnabled) {
            window
              .open(`${window.vueClientUrl}/agents/${item.id}`, '_blank')
              .focus()
          } else {
            window.open(`/connections/${item.id}/edit`, '_blank').focus()
          }
        }
      },
    }
  })

  return sectionConfigs
}

export default {
  components: {
    VueAutosuggest,
  },
  data() {
    return {
      debouncedFetchResults: debounce((text) => {
        this.fetchResults(text)
      }, 500),
      inputProps: {
        id: 'autosuggest__input',
        placeholder: 'Search everything...',
      },
      paginated: {},
      suggestions: [],
      searching: false,
      sectionConfigs: buildSectionConfigs(this),
      query: '',
      previousQuery: '',
      company: null,
    }
  },
  methods: {
    formatDate(date) {
      return moment(date).format('[On] MM/DD/YYYY [at] h:mm a')
    },
    async fetchResults(text) {
      if (!text || (text && text.length === 0)) {
        this.suggestions = []
        return
      }

      this.searching = true
      this.suggestions = []
      this.selected = null

      const response = await axios.get(
        `/api/v2/searchables?filter[fulltext]=${this.query}`
      )

      searchableTypes.forEach((searchableType) => {
        const name = searchableType.type
        const data = response.data.data.filter((row) => row.type === name) || []
        this.previousQuery = this.query

        if (name === 'connection' && data.length >= 5) {
          this.paginated.connection = true
          this.suggestions.push({ name, data: data.slice(0, 5) })
        } else if (data.length > 0) {
          this.suggestions.push({ name, data })
        }
      })

      this.searching = false
    },
    shouldRenderSuggestions(size, loading) {
      return (
        size >= 0 &&
        !loading &&
        this.query.match(/\S/) &&
        this.previousQuery.match(/\S/)
      )
    },
    async fetchCompany() {
      console.log('getting /me')
      const response = await axios.get(
        '/api/v2/users/me?include[]=profile.company'
      )
      this.company = response.data.included.find(
        (element) => element.type === 'company'
      )
    },
  },
  computed: {
    hasAgentsReportsEnabled() {
      return (
        this.company.attributes.settings.enable_agents_reports &&
        this.company.attributes.settings.enable_advanced
      )
    },
  },
  mounted() {
    this.fetchCompany()
  },
}
</script>

<style>
#sitewide-search-container .fa-search,
#autosuggest__input::placeholder {
  color: #888888;
}

#autosuggest__input {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;

  outline: none;
  position: relative;
  display: block;
  font-size: 15px;
  border: 1px solid #888888;
  padding: 15px;
  margin: 12px;
  width: 400px;
  height: 40px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

#autosuggest__input:focus {
  box-shadow: none;
}

#autosuggest__input.autosuggest__input-open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

.autosuggest__results {
  font-weight: 300;
  margin: 0;
  position: absolute;
  z-index: 10000001;
  width: 100%;
  background: white;
  border: 1px solid #e0e0e0;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 0px;
  overflow: scroll;
  overflow-x: hidden;
  max-height: 70vh;
}

.autosuggest__results ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.autosuggest__results-before {
  background: whiteSmoke;
  padding: 10px 15px;
}

.autosuggest__results-after {
  background: whiteSmoke;
  padding: 15px;
}

.autosuggest__results-item {
  cursor: pointer;
  padding: 15px 15px !important;
  border-bottom: 1px solid whiteSmoke;
  line-height: inherit !important;
}

#autosuggest ul:nth-child(1) > .autosuggest__results_title {
  border-top: none;
}

.autosuggest__results .autosuggest__results_title {
  color: gray;
  font-size: 11px;
  margin-left: 0;
  padding: 15px 13px 5px;
  border-top: 1px solid lightgray;
}

autosuggest__results-item--highlighted
  autosuggest__results-item
  .autosuggest__results
  .autosuggest__results-item:active,
.autosuggest__results .autosuggest__results-item:hover,
.autosuggest__results .autosuggest__results-item:focus,
.autosuggest__results
  .autosuggest__results-item.autosuggest__results-item--highlighted {
  background-color: #ddd;
}

.connection-agency,
.connection-email-phone {
  text-transform: none;
  padding-top: 3px;
}
</style>
